import React, { useState, useEffect } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Box,
}
  from "@mui/material"
import { terminateEmp } from "../../Actions/commonController"
import { toast } from "react-toastify"
import ResponsiveDatePicker from "../DatePicker/ResponsiveDatePicker"
import dayjs from "dayjs"

export default function TerminateModal({ open, onClose, onConfirm, empId }) {
  const [loading, setLoading] = useState(false)
  const [terminationType, setTerminationType] = useState("")
  const [reason, setReason] = useState("")
  const [lastWorkingDay, setLastWorkingDay] = useState(dayjs().add(30, 'day'))
  const [effectiveEndDate, setEffectiveEndDate] = useState(dayjs())
  const [manualLastWorkingDay, setManualLastWorkingDay] = useState(false)

  useEffect(() => {
    if (open) {
      setTerminationType("")
      setReason("")
      setLastWorkingDay(dayjs().add(30, "day"))
      setEffectiveEndDate(dayjs())
      setManualLastWorkingDay(false)
    }
  }, [open, empId])

  const handleChangeTerminationType = (event) => {
    setTerminationType(event.target.value)
  }

  const handleChangeReason = (event) => {
    setReason(event.target.value)
  }

  const handleEffectiveEndDateChange = (event) => {
    const parsedDate = dayjs(event.target.value, "DD/MM/YYYY")

    if (!parsedDate.isValid()) {
      toast.error("Invalid date selected")
      return
    }

    setEffectiveEndDate(parsedDate)

    if (!manualLastWorkingDay) {
      const newLastWorkingDay = parsedDate.add(30, 'day')
      setLastWorkingDay(newLastWorkingDay)
    } else {
      setManualLastWorkingDay(false)
    }
  }

  const handleLastWorkingDayChange = (event) => {
    const parsedDate = dayjs(event.target.value, "DD/MM/YYYY")

    if (!parsedDate.isValid()) {
      toast.error("Invalid date selected")
      return
    }

    const maxAllowedLastWorkingDay = effectiveEndDate.add(30, 'day')

    if (parsedDate.isAfter(maxAllowedLastWorkingDay)) {
      toast.error("Last working day cannot be more than 30 days after the effective end date.")
      return
    }

    setLastWorkingDay(parsedDate)
    setManualLastWorkingDay(true)
  }

  const terminateEmployee = async () => {
    if (!terminationType || !reason || !lastWorkingDay) {
      toast.error("Please fill in all the required fields.")
      return
    }

    try {
      setLoading(true)
      let APIData = {
        empid: empId,
        type: terminationType,
        reason: reason,
        lastWorkingDay: lastWorkingDay.format("YYYY-MM-DD"),
        eed: effectiveEndDate.format("YYYY-MM-DD"),
      }

      let { status } = await terminateEmp(APIData)
      if (status === "success") {
        onConfirm()
      } else {
        toast.error("Error terminating employee! (Contact administrator)")
      }
      setLoading(false)
    } catch (err) {
      toast.error("Error in server! (Contact administrator)")
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Confirmation Termination</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" style={{ fontFamily: "Open Sans", marginBottom: "16px" }}>
            Are you sure you want to terminate this employee?
          </Typography>
        </DialogContentText>
        <RadioGroup
          aria-label="termination-type"
          name="termination-type"
          value={terminationType}
          onChange={handleChangeTerminationType}
        >
          <FormControlLabel value="Terminated" control={<Radio />} label="Terminate" />
          <FormControlLabel value="Resigned" control={<Radio />} label="Resigned" />
        </RadioGroup>

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          <ResponsiveDatePicker
            name="eed"
            label="Effective End Date"
            defValue={effectiveEndDate.format("DD/MM/YYYY")}
            handleDate={handleEffectiveEndDateChange}
            sx={{ flex: 1 }}
          />

          <ResponsiveDatePicker
            name="lwd"
            label="Last Working Day"
            defValue={lastWorkingDay.format("DD/MM/YYYY")}
            handleDate={handleLastWorkingDayChange}
            sx={{ flex: 1 }}
          />
        </Box>

        <TextField
          autoFocus
          margin="dense"
          id="reason"
          label="Reason"
          type="text"
          multiline
          rows={4}
          fullWidth
          value={reason}
          onChange={handleChangeReason}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between", padding: "20px" }}>
        <Button
          style={{
            backgroundColor: "#FF0000",
            color: "white",
            width: "60px",
            height: "35px",
          }}
          onClick={onClose}
          disabled={loading}
        >
          No
        </Button>
        <Button
          sx={{
            backgroundColor: loading ? "primary.main" : "primary.main",
            color: "white",
            "&:hover": {
              backgroundColor: loading ? "primary.dark" : "primary.dark",
            },
            width: "60px",
            height: "35px",
          }}
          onClick={terminateEmployee}
          autoFocus
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} sx={{ color: "white" }} /> : "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
