export const utcDate = (data) => {
    // Convert ISO date string to a Date object in UTC time
    return new Date(data);  
}

export const formatUtcDate = (dateStr) => {
    const parsedDate = utcDate(dateStr);  // Parse the ISO date string to a Date object

    const day = parsedDate.getUTCDate().toString().padStart(2, '0');
    const month = (parsedDate.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const year = parsedDate.getUTCFullYear();

    let hours = parsedDate.getUTCHours();
    const minutes = parsedDate.getUTCMinutes().toString().padStart(2, '0');
    const seconds = parsedDate.getUTCSeconds().toString().padStart(2, '0');

    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 24-hour to 12-hour format

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    return `${day}-${month}-${year} | ${formattedTime}`;
};