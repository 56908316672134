import React, { useEffect, useState, useRef } from "react"
import {
    Box,
    Button,
    Popover,
    DialogContent,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    TextField,
    List,
    ListItem,
    InputAdornment,
    Badge,
} from "@mui/material"
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    useGridApiContext,
} from "@mui/x-data-grid"
import FilterListIcon from "@mui/icons-material/FilterList"
import AddIcon from "@mui/icons-material/Add"
import CustomFilterPanel from "./CustomFilterPanel"
import { downloadEmployeeReports } from "../../Actions/commonController"
import { saveAs } from "file-saver"
import ExcelJS from "exceljs"
import moment from "moment"
import DownloadIcon from "@mui/icons-material/Download"
import ViewColumnIcon from "@mui/icons-material/ViewColumn"
import SearchIcon from '@mui/icons-material/Search'
import { toast } from "react-toastify"

const FIELD_LABELS = {
    empid: 'Emp ID',
    name: 'Name',
    email: 'Email',
    gender: 'Gender',
    nationality: 'Nationality',
    dob: 'Date of Birth',
    age: "Age",
    passportno: 'Passport No.',
    passportstatus: "Passport Status",
    passportexpiry: 'Passport Expiry',
    contactuae: 'Contact UAE',
    maritalstatus: 'Marital Status',
    religion: 'Religion',
    hiredfrom: 'Hired From',
    dateofjoin: 'Date of Join (Actual)',
    designation: 'Designation',
    officeloc: 'Office Location',
    bankname: 'Bank Name',
    dept: 'Department',
    division: 'Division',
    sourcecode: 'Source Code',
    linemanager: 'Line Manager',
    teamleader: 'Team Leader',
    visaid: 'UID',
    dateofjoinmol: 'MOL Start Date',
    workpermitnumber: 'Work Permit Number',
    workpermitstartdate: "Work Permit Start Date",
    workpermitexpiry: 'Work Permit Expiry',
    personalcodemohre: 'Personal Code (MOHRE)',
    contracttype: 'Contract Type',
    sponsorname: 'Sponsor Name',
    labourcard: 'Labour Card',
    visafileno: 'Visa File No.',
    visadesignation: 'Visa Designation',
    visatype: 'Visa Status',
    visacancellation: 'Visa Cancellation Date',
    visaexpiry: 'Visa Expiry',
    eidno: 'Emirates ID No.',
    gpssa: "GPSSA",
    insuredno: "Insured No.",
    insureddate: 'Insured Date',
    contacthome: 'Contact Home',
    emergencycontactuaename: 'Emergency Contact UAE Name',
    emergencycontactuaeno: 'Emergency Contact UAE No.',
    emergencycontacthomename: 'Emergency Contact Home Name',
    emergencycontacthomeno: 'Emergency Contact Home No.',
    companysimnumber: 'Company SIM Number',
    simissuedate: 'SIM Issue Date',
    simlastdate: 'SIM Last Date',
    simplan: 'SIM Plan',
    empstatus: 'Employee Status',
    statusremarks: 'Status Remarks',
    lwd: 'Last Working Day',
    eed: 'Effective End Date',
    medicalprovider: 'Medical Provider',
    medicalexpiry: 'Medical Expiry',
    medicalcardno: 'Medical Card No.',
    basicsalary: 'Basic Salary',
    housingallowance: 'Housing Allowance',
    transportallowance: 'Transport Allowance',
    foodallowance: 'Food Allowance',
    mobileallowance: 'Mobile Allowance',
    otherallowance: 'Other Allowance',
    totalsalary: 'Total Salary',
    contractsalary: 'Contract Salary',
    accnumber: 'Bank Account Number',
    iban: 'IBAN',
    salarybankname: 'Salary Bank Name',
    docstatus: 'Document Status',
    remarks: 'Remarks',
    createdAt: 'Created At',
    createdBy: 'Created By',
    previousempemail: 'Previous Employee Email',
    verificationstatus: 'Verification Status',
    emailsenton: 'Email Sent On',
    repliedon: 'Email Replied On'
}

const VISIBLE_FIELDS = [
    'empid',
    'name',
    'email',
    'gender',
    'nationality',
    'dob',
    'age',
    'passportno',
    'passportstatus',
    'passportexpiry',
    'contactuae',
    'maritalstatus',
    'religion',
    'hiredfrom',
    'dateofjoin',
    'designation',
    'officeloc',
    'bankname',
    'dept',
    'division',
    'sourcecode',
    'linemanager',
    'teamleader',
    'visaid',
    'dateofjoinmol',
    'workpermitnumber',
    'workpermitstartdate',
    'workpermitexpiry',
    'personalcodemohre',
    'contracttype',
    'sponsorname',
    'labourcard',
    'visafileno',
    'visadesignation',
    'visatype',
    'visacancellation',
    'visaexpiry',
    'eidno',
    'gpssa',
    'insuredno',
    'insureddate',
    'contacthome',
    'emergencycontactuaename',
    'emergencycontactuaeno',
    'emergencycontacthomename',
    'emergencycontacthomeno',
    'companysimnumber',
    'simissuedate',
    'simlastdate',
    'simplan',
    'empstatus',
    'statusremarks',
    'lwd',
    'eed',
    'medicalprovider',
    'medicalexpiry',
    'medicalcardno',
    'basicsalary',
    'housingallowance',
    'transportallowance',
    'foodallowance',
    'mobileallowance',
    'otherallowance',
    'totalsalary',
    'contractsalary',
    'accnumber',
    'iban',
    'salarybankname',
    'docstatus',
    'remarks',
    'createdAt',
    'createdBy',
    'previousempemail',
    'verificationstatus',
    'emailsenton',
    'repliedon'
]

const dateFields = [
    'dob',
    'passportexpiry',
    'dateofjoin',
    'dateofjoinmol',
    'workpermitstartdate',
    'workpermitexpiry',
    'visaexpiry',
    'lwd',
    'eed',
    'medicalexpiry',
    'visacancellation',
    'simissuedate',
    'simlastdate',
    'insureddate',
    'createdAt',
    'emailsenton',
    'repliedon'
]

function CustomToolbar({ filters, setFilters, handleApplyFilters, handleRemoveAllFilters, columns, setSelectedColumns, selectedColumns, setData }) {

    const [filterDialogOpen, setFilterDialogOpen] = useState(false)
    const [activeFiltersCount, setActiveFiltersCount] = useState(0)
    const [loader, setLoader] = useState(false)
    const filterButtonRef = useRef(null)
    const columnButtonRef = useRef(null)
    const apiRef = useGridApiContext()
    const [anchorEl, setAnchorEl] = useState(null)
    const [loading, setLoading] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')

    const handleColumnsClick = (event) => {
        setAnchorEl(columnButtonRef.current)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleToggleColumn = (field) => {
        setSelectedColumns((prevSelected) => {
            if (prevSelected.includes(field)) {
                return prevSelected.filter((col) => col !== field)
            } else {
                return [...prevSelected, field]
            }
        })
    }

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase())
    }

    const handleSelectAll = () => {
        setSelectedColumns(VISIBLE_FIELDS)
    }

    const handleRemoveAll = () => {
        setSelectedColumns([])
        setData([])
    }

    const filteredFields = VISIBLE_FIELDS.filter((field) =>
        FIELD_LABELS[field]?.toLowerCase().includes(searchTerm)
    )

    const open = Boolean(anchorEl)

    const openFilterDialog = () => {
        if (filters.length === 0) {
            setFilters([{ field: '', operator: '', value: '' }])
        }
        setFilterDialogOpen(true)
    }

    const closeFilterDialog = () => {
        setFilterDialogOpen(false)
    }

    const addFilter = () => {
        setFilters([...filters, { field: '', operator: '', value: '' }])
    }

    const applyFilters = () => {
        const appliedFiltersCount = filters.filter(filter => {
            return filter.operator === 'isEmpty' || filter.operator === 'isNotEmpty' || (filter.value && filter.value !== '')
        }).length
        setActiveFiltersCount(appliedFiltersCount)
        handleApplyFilters()
        closeFilterDialog()
    }

    const handleDownloadExcel = async () => {
        try {

            setLoader(true)

            if (!apiRef.current || !apiRef.current.state) {
                console.error("apiRef or state is not initialized.")
                toast.error("An error has occurred! (Contact Administrator)")
                setLoader(false)
                return
            }

            const gridState = apiRef.current.state

            const orderedFields = gridState?.columns?.orderedFields || []
            const columnVisibilityModel = gridState?.columns?.columnVisibilityModel || {}

            const selectedColumns = orderedFields.filter((field) => columnVisibilityModel[field] !== false)

            if (!selectedColumns || selectedColumns.length === 0) {
                console.error("No visible columns found in grid state:", gridState)
                toast.error("No columns are available to download.")
                setLoader(false)
                return
            }

            const APIData = {
                fields: selectedColumns.join(','),
                filters: filters.map((item) => ({
                    columnField: item.field,
                    operatorValue: item.operator,
                    value: Array.isArray(item.value)
                        ? item.value.map(date => moment(date, 'DD/MM/YYYY').isValid() ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') : date)
                        : moment(item.value, 'DD/MM/YYYY').isValid() ? moment(item.value, 'DD/MM/YYYY').format('YYYY-MM-DD') : item.value,
                }))
            }

            const { status, data } = await downloadEmployeeReports(APIData)
            if (status === "success") {
                const workbook = new ExcelJS.Workbook()
                const worksheet = workbook.addWorksheet('Employee Reports')

                worksheet.columns = selectedColumns.map((field) => ({
                    header: FIELD_LABELS[field] || field.toUpperCase(),
                    key: field,
                    width: 20,
                }))

                data.forEach(row => {
                    const newRow = {}
                    selectedColumns.forEach((key) => {
                        newRow[key] = row[key]
                    })
                    worksheet.addRow(newRow)
                })

                const buffer = await workbook.xlsx.writeBuffer()
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

                saveAs(blob, `Employee_Reports_${Date.now()}.xlsx`)
            } else {
                toast.error("Failed to download employee report!")
            }
        } catch (error) {
            console.error('Error generating Excel file:', error)
        }
        setLoader(false)
    }

    useEffect(() => {
        if (apiRef.current) {
            const gridState = apiRef.current.state
            const visibleColumns = gridState?.columns?.visibleFields || VISIBLE_FIELDS

            if (!visibleColumns || visibleColumns.length === 0) {
                console.error("No visible columns found. Using fallback.")
            }
        }
    }, [apiRef])


    return (
        <Box sx={{ padding: "20px", backgroundColor: '#EAF1F3' }}>

            <GridToolbarContainer>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>

                    {/* Column Button */}
                    <Button
                        sx={{
                            color: 'white !important',
                            backgroundColor: '#1976d2',
                            '&:hover': {
                                backgroundColor: '#1976d2',
                            },
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            fontFamily: 'sans-serif',
                            fontSize: "13px",
                            fontWeight: "lighter"
                        }}
                        onClick={handleColumnsClick}
                        ref={columnButtonRef}
                    >
                        <ViewColumnIcon />
                        Columns
                    </Button>

                    {/* Filter Button with Badge */}
                    <Badge
                        badgeContent={activeFiltersCount}
                        color="secondary"
                        invisible={activeFiltersCount === 0}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={openFilterDialog}
                            startIcon={<FilterListIcon />}
                            sx={{ display: 'flex', alignItems: 'center', fontFamily: 'sans-serif', fontSize: "13px", fontWeight: "lighter" }}
                            ref={filterButtonRef}
                        >
                            Filters
                        </Button>
                    </Badge>

                    {/* Export Button */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDownloadExcel}
                        startIcon={!loader && <DownloadIcon />}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: 'sans-serif',
                            fontSize: "13px",
                            fontWeight: "lighter",
                            position: 'relative',
                            minWidth: '100px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                            }}
                        >
                            {loader ? (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: 'white',
                                        position: 'absolute',
                                    }} />) : ("Export")}
                        </Box>
                    </Button>
                </Box>
            </GridToolbarContainer >

            {/* Popover for column selection */}
            <Popover
                open={open}
                anchorEl={columnButtonRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{ style: { width: '300px', maxHeight: '400px', display: 'flex', flexDirection: 'column' } }}
            >
                <Box sx={{ padding: '10px' }}>
                    {/* Search Bar  for columns*/}
                    <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Search columns..."
                        onChange={handleSearchChange}
                        sx={{ marginBottom: '10px' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                {/* Scrollable list of columns */}
                <Box sx={{ overflowY: 'auto', flexGrow: 1, padding: '0 10px' }}>
                    <List dense>
                        {filteredFields.length > 0 ? (
                            filteredFields.map((field) => (
                                <ListItem key={field} dense>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedColumns.includes(field)}
                                                onChange={() => handleToggleColumn(field)}
                                                name={field}
                                            />
                                        }
                                        label={FIELD_LABELS[field] || field}
                                    />
                                </ListItem>
                            ))
                        ) : (
                            <ListItem>No columns found.</ListItem>
                        )}
                    </List>
                </Box>

                {/* Select All / Remove All Buttons at the bottom */}
                <Box sx={{ padding: '10px', borderTop: '1px solid #ddd' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSelectAll}
                        sx={{ minWidth: '120px', marginRight: '30px' }}
                    >
                        Select All
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleRemoveAll}
                        sx={{ minWidth: '120px' }}
                    >
                        Remove All
                    </Button>
                </Box>
            </Popover>

            {/* Popover for filter selection */}
            <Popover
                anchorEl={filterButtonRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={filterDialogOpen}
                onClose={closeFilterDialog}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    <CustomFilterPanel
                        filters={filters}
                        setFilters={setFilters}
                    />
                    <Box className="filter-buttons">
                        <Button variant="contained"
                            onClick={addFilter}
                            startIcon={<AddIcon />}>
                            Add Filter
                        </Button>
                        <Button variant="contained"
                            onClick={() => {
                                handleRemoveAllFilters()
                                setActiveFiltersCount(0)
                                closeFilterDialog()
                            }}
                            sx={{
                                background: "red",
                                "&:hover": {
                                    background: "red",
                                },
                            }}
                        >

                            Remove All
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                background: "#1DAC3A",
                                "&:hover": {
                                    background: "#1DAC3A",
                                },
                            }}
                            onClick={() => {
                                applyFilters()
                            }}>
                            Apply Filters
                        </Button>
                    </Box>
                </DialogContent>
            </Popover>
        </Box >
    )
}

export default CustomToolbar